
import Vue from "vue";
import Multiselect from "vue-multiselect";
import { mapGetters } from "vuex";
import { HTTP } from "@/utils/http-common";
import { IEdition, IPackage, IUser, ICompany } from "@/data-types";

export default Vue.extend({
  components: { Multiselect },
  props: {
    formLayout: {
      type: Array,
      required: true,
    },
    companiesList: {
      type: Array,
    },
    newMessage: {
      type: [Boolean, String],
    },
    itemId: {
      type: [String, Number],
      required: false,
    },
    editionId: {
      type: [String, Number],
      required: false,
    },
    companyId: {
      type: [String, Number],
      required: false,
    },
    editingModel: {
      type: Object,
      default: {},
    },
    requestUrl: {
      type: String,
    },
  },
  data(): any {
    return {
      dataModel: {},
      packages: {},
      users: {},
      publishMessage: "",
      multiselectModel: [],
      mappedArray: [],
      compId: 0,
      edId: 0,
      passwordErrors: null,
    };
  },
  computed: {
    ...mapGetters(["editionsList"]),
  },
  methods: {
    customLabel({ title, desc }: any) {
      return `${title} – ${desc}`;
    },
    publishContent() {
      if (this.requestUrl === "api/user") {
        this.dataModel.companies = [];
        if (this.multiselectModel.length) {
          this.companiesList.forEach((company: any) => {
            if (this.multiselectModel.indexOf(company.name) !== -1) {
              this.dataModel.companies.push(company.id);
            }
          });
        } else {
          this.dataModel.companies = this.itemId;
        }
      }
      if (this.dataModel.companies) {
        this.dataModel.companies = this.dataModel.companies.toString();
      }
      this.passwordErrors = null;
      HTTP.post(`${this.requestUrl}`, this.dataModel, {
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          this.publishMessage = "Dodano nowy element!";
          if (this.requestUrl === "api/user") {
            (this.mappedArray = {
              id: response.data.data.id,
              companies: response.data.data.companies.map((company: any) => {
                return company.name;
              }),
              role: response.data.data.role,
              email: response.data.data.email,
              name: response.data.data.name,
            }),
              this.$store.dispatch("addContent", this.mappedArray);
          } else {
            this.$store.dispatch("addContent", response.data.data);
          }
        })
        .catch((err) => {
          if (err?.response?.data?.password) {
            this.passwordErrors = err.response.data.password;
            return;
          }
          const RESPONSE_ERRORS = err.response.data.data.message;
          const ERRORS_ARR: any = [];

          Object.keys(RESPONSE_ERRORS).forEach((key) => {
            ERRORS_ARR.push(RESPONSE_ERRORS[key]);
          });

          this.publishMessage = "Wystąpił błąd:<br>" + ERRORS_ARR.join("<br>");
        });
    },
    publishEditedContent() {
      if (this.requestUrl === "api/user") {
        this.dataModel.companies = [];
        this.companiesList.forEach((company: any) => {
          if (this.multiselectModel.indexOf(company.name) !== -1) {
            this.dataModel.companies.push(company.id);
          }
        });
      }
      if (this.dataModel.companies) {
        this.dataModel.companies = this.dataModel.companies.toString();
      }

      HTTP.put(`${this.requestUrl}/${this.itemId}`, this.dataModel, {
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          this.publishMessage = "Element został zedytowany!";
          if (this.requestUrl === "api/user") {
            (this.mappedArray = {
              id: response.data.data.id,
              companies: response.data.data.companies.map((company: any) => {
                return company.name;
              }),
              role: response.data.data.role,
              email: response.data.data.email,
              name: response.data.data.name,
            }),
              this.$store.dispatch("editContent", this.mappedArray);
          } else {
            this.$store.dispatch("editContent", response.data.data);
          }
        })
        .catch(() => {
          this.publishMessage = "Wystąpił błąd";
        });
    },
    addExistingUser() {
      const data = {
        company: this.itemId,
        user: this.users.find(
          (user: IUser) => user.email === this.dataModel.user
        ).id,
      };

      HTTP.post(`api/company/user/${data.company}/${data.user}`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          this.publishMessage = "Użytkownik zostały przypisany!";
          this.$store.dispatch("editContent", response.data.data);
        })
        .catch(() => {
          this.publishMessage = "Wystąpił błąd";
        });
    },
    addEdition() {
      this.dataModel.company = this.itemId;
      this.editionsList.forEach((edition: IEdition) => {
        if (edition.name === this.dataModel.edition) {
          this.dataModel.edition = edition.id;
        }
      });
      this.packages.forEach((item: IPackage) => {
        if (item.name === this.dataModel.package) {
          this.dataModel.package = item.id;
        }
      });
      HTTP.post(
        `api/company/package/${this.itemId}/${this.dataModel.package}/${this.dataModel.edition}`,
        this.dataModel,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => {
          this.publishMessage = "Edycja została przypisana!";
          this.$store.dispatch("addContent", response.data.data);
          location.reload();
        })
        .catch(() => {
          this.publishMessage = "Wystąpił błąd";
        });
    },
    getPackagesData() {
      HTTP.get("api/packages")
        .then((response) => {
          this.packages = response.data.data;
        })
        .catch((e) => {
          // console.error(e);
        });
    },
    getUsersData() {
      HTTP.get("api/users")
        .then((response) => {
          this.users = response.data.data;
        })
        .catch((e) => {
          // console.error(e);
        });
    },
    sendData() {
      const sendData = new FormData();
      sendData.append("key", "_ed_booth_number");
      sendData.append("value", this.dataModel._ed_booth_number);
      sendData.append("confirmed", "0");

      HTTP.post(`api/detail/${this.edId}/${this.compId}`, sendData)
        .then((response) => {
          this.publishMessage = "Dodano numer stoiska";
        })
        .catch((error) => {
          console.error(error);
          this.publishMessage = "Nie udało się dodać numeru stoiska";
        });
    },
  },
  mounted() {
    this.dataModel = this.editingModel;
    if (!this.itemId) {
      if (this.editingModel.companies) {
        this.editingModel.companies.forEach((company: ICompany) => {
          this.multiselectModel.push(company);
        });
      }
    }
    this.dataModel.companies = [];
    this.getPackagesData();
    this.getUsersData();
    this.edId = this.editionId;
    this.compId = this.companyId;
  },
  watch: {
    editionId: function () {
      this.edId = this.editionId;
    },
    companyId: function () {
      this.compId = this.companyId;
    },
  },
});
